function init() {
    var imgDefer = document.getElementsByTagName('img');
    for (var i = 0; i < imgDefer.length; i++) {
        if (imgDefer[i].getAttribute('data-src')) {
            imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src'));
        }
    }
}
window.onload = init;

// startsWith polyfill for IE support
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function(search, rawPos) {
            var pos = rawPos > 0 ? rawPos|0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

// Generic jQuery commands:
$(document).ready(function() {
	
  // Insert current year:
  var currentYear = new Date().getFullYear();
  $('.js-currentyear').text(currentYear);


  $('#report-emergency-init').popover({
      content: function() {
          return $('#report-emergency').html();
      },
      container: '.action-bar--desktop',
      placement: 'bottom',
      html: true
  });

  $('#report-emergency-init').on('show.bs.popover', function() {
    $('#report-outage-init').popover('hide');
  });
  $('#report-outage-init').popover({
      content: function() {
          return $('#outage-modal').html();
      },
      container: '.action-bar--desktop',
      placement: 'bottom',
      html: true
  });

  $('#report-outage-init').on('show.bs.popover', function() {
    $('#report-emergency-init').popover('hide');
  });

  $('.content-container').click(function() {
    $('#report-outage-init, #report-emergency-init').popover('hide');
  });

  $('#close-alert').click(function(e) {
      e.preventDefault();
      $(this).parents('.alert-banner').removeClass('is-visible');
  });

  $('.dropdown-toggle').dropdown({
      offset: 1000,
      flip: true
  });
  // Comment

  // Display mobile search modal:
  $('#search-modal').on('shown.bs.modal', function(e) {
    $('#searchbox-modal').focus();
  });

  // Display external link icon arrow after each external link:
  var pageUrl = location.origin;
  $('a').each(function(){
    var destination = $(this).attr('href');
    if(destination && destination.startsWith('http')) {
      if( destination.startsWith(pageUrl) ) {
        return;
      } else {
        $(this).addClass('external-link');
      }
    }
  });

  var cid = $("#cid").val();
  $("#navItem_" + cid).toggleClass("active");

  $("#printPage").click(function(){
    window.print();
    return false;
  });


});
