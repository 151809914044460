$('.js-phone-number').keypress(function(e) {
  var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
});

$('.input-line').focus(function() {
  $(this).parents('.form-group').addClass('formline-active');
});
$('.input-line').blur(function() {
  $(this).parents('.form-group').removeClass('formline-active');
});